import React, { Component } from "react";
import { Link } from "react-router-dom";

import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageAcountDashboard extends Component {
  render() {
    return (
      <Main>
        <div>
          <section className="page-cover dashboard">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">My Account</h1>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">My Account</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="innerpage-wrapper">
            <div id="dashboard" className="pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="dashboard-wrapper">
                      <div className="row">
                        <div className="col-12 col-md-2 col-lg-2 dashboard-nav">
                          <ul className="nav nav-tabs">
                            <li className="nav-item active">
                              <Link
                                className="nav-link"
                                to="/account_dashboard"
                              >
                                <span>
                                  <i className="fa fa-dashboard" />
                                </span>
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="nav-link" to="/account_profile">
                                <span>
                                  <i className="fa fa-user" />
                                </span>
                                Profile
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="nav-link" to="/account_booking">
                                <span>
                                  <i className="fa fa-briefcase" />
                                </span>
                                Booking
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-10 col-lg-10 dashboard-content">
                          <h2 className="dash-content-title">Dashboard</h2>
                          <div className="row info-stat">
                            <div className="col">
                              <div className="stat-block">
                                <span>
                                  <div class="pb-2">
                                    <img
                                      src="assets/images/service/rooms.svg"
                                      width="50px"
                                      alt="service"
                                    />
                                  </div>
                                </span>
                                <h3>1548</h3>
                                <p>Miles</p>
                              </div>
                            </div>
                            <div className="col">
                              <div className="stat-block">
                                <span>
                                  <div class="pb-2">
                                    <img
                                      src="assets/images/service/world-svgrepo-com.svg"
                                      width="50px"
                                      alt="service"
                                    />
                                  </div>
                                </span>
                                <h3>12%</h3>
                                <p>World</p>
                              </div>
                            </div>
                            <div className="col">
                              <div className="stat-block">
                                <span>
                                  <div class="pb-2">
                                    <img
                                      src="assets/images/service/building-svgrepo-com.svg"
                                      width="50px"
                                      alt="service"
                                    />
                                  </div>
                                </span>
                                <h3>312</h3>
                                <p>Cities</p>
                              </div>
                            </div>
                            <div className="col">
                              <div className="stat-block">
                                <span>
                                  <div class="pb-2">
                                    <img
                                      src="assets/images/service/airplane-svgrepo-com.svg"
                                      width="50px"
                                      alt="service"
                                    />
                                  </div>
                                </span>
                                <h3>102</h3>
                                <p>Trips</p>
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-listing recent-activity">
                            <h3 className="dash-listing-heading">
                              Recent Activites
                            </h3>
                            <div className="table-responsive">
                              <table className="table table-striped table-hover tborderbottom">
                                <tbody>
                                  <tr>
                                    <td>
                                      Your listing
                                      <span>The Star Travel</span> has been
                                      approved!
                                    </td>
                                    <td>
                                      <button className="btn btn-sm">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Your listing
                                      <span>The Star Travel</span> has been
                                      approved!
                                    </td>
                                    <td>
                                      <button className="btn btn-sm">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Your listing
                                      <span>The Star Travel</span> has been
                                      approved!
                                    </td>
                                    <td>
                                      <button className="btn btn-sm">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Your listing
                                      <span>The Star Travel</span> has been
                                      approved!
                                    </td>
                                    <td>
                                      <button className="btn btn-sm">
                                        <i className="fa fa-trash"></i>
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="dashboard-listing invoices">
                            <h3 className="dash-listing-heading">Invoices</h3>
                            <div className="table-responsive">
                              <table className="table table-striped table-hover tborderbottom">
                                <tbody>
                                  <tr>
                                    <td className="dash-list-icon invoice-icon">
                                      <div class="pb-2">
                                      <img
                                        src="assets/images/service/invoice-svgrepo-com.svg"
                                        width="30px"
                                        alt="invoice"
                                      />
                                    </div>
                                    </td>
                                    <td className="dash-list-text invoice-text">
                                      <h4 className="invoice-title">
                                        Professional Plan
                                      </h4>
                                      <ul className="list-unstyled list-inline invoice-info">
                                        <li className="list-inline-item invoice-status red">
                                          Unpaid
                                        </li>
                                        <li className="list-inline-item invoice-order">
                                          {" "}
                                          Order: #00214
                                        </li>
                                        <li className="list-inline-item invoice-date">
                                          {" "}
                                          Date: 25/08/2017
                                        </li>
                                      </ul>
                                    </td>
                                    <td className="dash-list-btn">
                                      <button className="btn btn-orange">
                                        View Invoice
                                      </button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="dash-list-icon invoice-icon">
                                      <div class="pb-2">
                                      <img
                                        src="assets/images/service/invoice-svgrepo-com.svg"
                                        width="30px"
                                        alt="invoice"
                                      />
                                    </div>
                                    </td>
                                    <td className="dash-list-text invoice-text">
                                      <h4 className="invoice-title">
                                        Professional Plan
                                      </h4>
                                      <ul className="list-unstyled list-inline invoice-info">
                                        <li className="list-inline-item invoice-status green">
                                          Paid
                                        </li>
                                        <li className="list-inline-item invoice-order">
                                          {" "}
                                          Order: #00214
                                        </li>
                                        <li className="list-inline-item invoice-date">
                                          {" "}
                                          Date: 25/08/2017
                                        </li>
                                      </ul>
                                    </td>
                                    <td className="dash-list-btn">
                                      <button className="btn btn-orange">
                                        View Invoice
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  }
}

export default PageAcountDashboard;
