import React, { Component } from "react";
import { Link } from "react-router-dom";

import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

class PageAcountBooking extends Component {
  render() {
    return (
      <Main>
        <div>
          <section className="page-cover dashboard">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">My Account</h1>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">My Account</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="innerpage-wrapper">
            <div id="dashboard" className="pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="dashboard-wrapper">
                      <div className="row">
                        <div className="col-12 col-md-2 col-lg-2 dashboard-nav">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to="/account_dashboard"
                              >
                                <span>
                                  <i className="fa fa-dashboard" />
                                </span>
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="nav-link" to="/account_profile">
                                <span>
                                  <i className="fa fa-user" />
                                </span>
                                Profile
                              </Link>
                            </li>
                            <li className="nav-item active">
                              <Link className="nav-link" to="/account_booking">
                                <span>
                                  <i className="fa fa-briefcase" />
                                </span>
                                Booking
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-10 col-lg-10 dashboard-content">
                          <h2 className="dash-content-title ">My Booking</h2>
                          <div className="card dashboard-listing booking-listing">
                            <div className="card-header">
                              <h4>List Booking</h4>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="table-responsive">
                                  <table className="table table-striped table-hover tborderbottom">
                                    <thead>
                                      <tr>
                                        <th className="text-center">No</th>
                                        <th className="text-center">Date</th>
                                        <th className="text-center">Detail</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td> 1 </td>
                                        <td className="dash-list-icon booking-list-date">
                                          <div className="b-date">
                                            <h3>18</h3>
                                            <p>October</p>
                                          </div>
                                        </td>
                                        <td className="dash-list-text booking-list-detail">
                                          <h3>Superior Room</h3>
                                          <ul className="list-unstyled booking-info">
                                            <li>
                                              <span>Booking Date:</span>{" "}
                                              26/12/2021 at 03:20 pm
                                            </li>
                                            <li>
                                              <span>Booking Details:</span> 3 to
                                              6 People
                                            </li>
                                            <li>
                                              <span>Client:</span> Lisa Smith
                                              <span className="line">|</span>
                                              lisasmith@youremail.com
                                              <span className="line">|</span>
                                              125 254 2578
                                            </li>
                                          </ul>
                                          <Link to="#" className="btn btn-orange"><i className="fa fa-print"></i> Print Details</Link>
                                        </td>
                                        <td className="dash-list-text booking-list-detail"> Booked </td>
                                        <td className="dash-list-btn">
                                          <button className="btn">
                                            Cancel
                                          </button>
                                          <br />

                                          <button className="btn btn-orange">
                                            Approve
                                          </button>
                                        </td>
                                      </tr>
                                      
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  }
}

export default PageAcountBooking;
