import React, { Component } from "react";
import { Link } from "react-router-dom";

import Main from "../../layout/Main";

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";


class PageAcountProfile extends Component {
  render() {
    return (
      <Main>
        <div>
          <section className="page-cover dashboard">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="page-title">My Account</h1>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item">My Account</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="innerpage-wrapper">
            <div id="dashboard" className="pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="dashboard-wrapper">
                      <div className="row">
                        <div className="col-12 col-md-2 col-lg-2 dashboard-nav">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <Link
                                className="nav-link"
                                to="/account_dashboard"
                              >
                                <span>
                                  <i className="fa fa-cogs" />
                                </span>
                                Dashboard
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                to="/account_profile"
                              >
                                <span>
                                  <i className="fa fa-user" />
                                </span>
                                Profile
                              </Link>
                            </li>
                            <li className="nav-item">
                              <Link className="nav-link" to="/account_booking">
                                <span>
                                  <i className="fa fa-briefcase" />
                                </span>
                                Booking
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-10 col-lg-10 dashboard-content">
                          <h2 className="dash-content-title">My Profile</h2>
                          <div className="card">
                            <div className="card-header">
                              <h4>Profile Details</h4>
                            </div>
                            <div className="card-body">
                              <form
                                method="post"
                              >
                                <div className="row">
                                  <div className="col-xs-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>Nama Lengkap Anda (wajib)</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nama Lengkap Anda (wajib)"
                                        name="namalengkap"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>Phone</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone"
                                        name="phone"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>Alamat Email</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        name="email"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>Alamat</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Alamat"
                                        name="alamat"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-6 col-lg-6">
                                    <div className="form-group">
                                      <label>No Rekening</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="No Rekening"
                                        name="no_rek"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label>Nama Pemegang Rekening</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Nama pemegang rekening"
                                        name="nama_pemegang_rek"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-xs-12 col-md-12 col-lg-12">
                                  <button
                                      className="btn btn-orange pull-right pl-3 pr-3"
                                      type="submit"
                                      name="submit"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  }
}

export default PageAcountProfile;
