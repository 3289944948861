import React, { Component } from "react";
import Header from "./Header";
import Headerlogin from "./Headerlogin";

import Footer from "./Footer";
import { Helmet, HelmetProvider } from "react-helmet-async";

function closeSearch() {
  document.getElementById("myOverlay").style.display = "none";
}

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      loadingAct: true,
    };
  }

  componentWillMount() {
    //loading
    setTimeout(() => {
      this.setState({ loadingAct: false });
    }, 100);
  }
  loading = () => 
    this.state.loadingAct === true ? (
      <div id="global-loader">
        <img
          src="assets/images/loaders/loader-4.svg"
          className="loader-img"
          alt="Loader"
        />
      </div>
    ) : (
      <div></div>
    );

  
  render() {
   // const children = this.props;
    return (
      <div>
        <HelmetProvider>
          <Helmet>
          <title>{this.props.title ? this.props.title : "No title"}</title>
          </Helmet>
        </HelmetProvider>
        <div className="wrapper">
          <div id="myOverlay" className="overlay">
            <span
              className="closebtn"
              onClick={closeSearch}
              title="Close Overlay"
            >
              ×
            </span>
            <div className="overlay-content">
              <form>
                <div className="form-group">
                  <div className="input-group">
                    <input
                      className="float-left"
                      type="text"
                      placeholder="Search.."
                      name="search"
                    />
                    <button className="float-left" type="submit">
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
       
          {this.state.resLogin==="00" ? <Headerlogin /> : <Header />}
          {this.props.children}

          <Footer />
        </div>
      </div>
    );
  }
}

export default Main;
