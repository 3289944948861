import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
const ComGridhari = (props) => {

  return (
    <>
       <tr>
							<td>{props.data.tanggal}</td>
							<td>{props.data.hari}</td>
							<td>
							<label><input type="radio" class="nradio" name="ntanggal" value={props.data.tanggal} tanggal={props.data.tanggal}  onClick={(e) => props.changefile(e,1)}/></label>
							</td>
						
		</tr>
	


     
    </>
  );
};

export default ComGridhari;
