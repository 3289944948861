import React, { Component } from "react";
import { Link,Redirect } from "react-router-dom";
import Comtemp from "./Comtemp";
import {Helmet} from "react-helmet";
import Main from "../../layout/Main";

class Succescheckout extends Component {
    constructor(props) {
        super(props);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get('kode');
    this.state = {
        cancel: false,
        show: false,
        loadpengajuan:"00",
        loadingAct: false,
        errors: false,
        foo: foo,
        fields: {
          id: "",
          
        },
        hh:{},
        list_jam: [],
        data_jam: {},
      };
    }
    dataAnggotaIndividu = () => {
        fetch("lmn7/datasucceschekout", {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            usersmp: "ttdatakeluarga",
            pwd: "12345",
            foo: this.state.foo
          }),
          headers: new Headers({
            "content-type": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.kode === "00") {
              this.setState({
                hh: data.data[0],
                list_jam: data.data2,
                data_jam: JSON.stringify(data.data2),
              });
              //console.log(this.state.data_anggotaIndividu);
            }else{
                this.setState({
                    loadpengajuan: "88"
                  });
            }
          })
          .catch((error) => {
            this.setState({
              errors: true,
            });
          });
      };
      componentDidMount() {
         this.dataAnggotaIndividu();
       
      }  
  render() {
    const { loadpengajuan,foo } = this.state;
    if(foo==null){
       // return <Redirect to="/" />;
    }
    if(loadpengajuan==="88"){
         //alert(loadpengajuan);
     }

    return (
      <>
      <Helmet>
         <title>Chekout sukses</title>
         <meta name="Keywords" content="Chekout sukses"></meta>
       <meta name="Description" content="Chekout sukses"></meta>

       </Helmet>
     <Main title="Chekout sukses">
    
        <section className="page-cover style style5 p-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="page-title">CHEHKOUT Berhasil</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="innerpage-wrapper">
          <div id="thank-you" className="innerpage-section-padding">
            <div className="container">
              <div className="row">
              <div className="col-3 col-md-3 col-lg-6 col-xl-3 content-side"></div>
                <div className="col-6 col-md-6 col-lg-6 col-xl-6 content-side">
                  <div className="space-right">
                   
                  </div>
                  <div className="traveler-info">
                    <h3 className="t-info-heading">
                      <span>
                        <i className="fa fa-info-circle" />
                      </span>
                      Checkout Information
                    </h3>
                    <div className="table-responsive">
                    {(() => {
                        if (this.state.loadpengajuan === "00") {
                            return(
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <td width="40%">Nama</td>
                                        <td width="5%">:</td>
                                        <td width="55%">{this.state.hh.nama}</td>
                                    </tr>
                                    <tr>
                                        <td width="40%">Unit/Lembaga</td>
                                        <td width="5%">:</td>
                                        <td width="55%">{this.state.hh.unit}</td>
                                    </tr>
                                    <tr>
                                        <td >Alamat</td>
                                        <td >:</td>
                                        <td >{this.state.hh.alamat}</td>
                                    </tr>
                                    <tr>
                                        <td >No HP</td>
                                        <td >:</td>
                                        <td >{this.state.hh.nohp}</td>
                                    </tr>
                                    <tr>
                                        <td >Tanggal Pakai</td>
                                        <td >:</td>
                                        <td >{this.state.hh.tgl_pakai} s/d {this.state.hh.tgl_selesai}</td>
                                    </tr>
                                    <tr>
                                        <td >Download tanda terima</td>
                                        <td >:</td>
                                        <td ><a href={this.state.hh.nfiletandaterima} target="_blank">Downloads</a></td>
                                    </tr>
                                    </tbody>
                                </table>
                                
                            );
                        }else{
                            return(
                                <table className="table">
                                <tbody>
                                  <tr>
                                    <td width="40%">Booking Number</td>
                                    <td width="5%">:</td>
                                    <td width="55%">DATA TIDAK TERSEDIA</td>
                                  </tr>
                                  </tbody>  
                                </table>
                            );
                          }
                
                         
                    })()}
                      
                      <table id="tablepersyaratan1" className="table text-sm table-hover table-striped table-bordered mb-0 text-md-nowrap text-lg-nowrap text-xl-nowrap">
                              <thead>
                                <tr>
                                    <th width="15px" className="tx-center">NO</th>
                                    <th style={{ width: "100px" }} className="tx-center" >Jam</th>
                                   </tr>
                                </thead>
                                <tbody>
                                  {this.state.errors === false ? (
                                    this.state.list_jam.map(
                                      (data_jam, i) => {
                                        return (
                                          <Comtemp
                                            key={data_jam.id}
                                            nom={i + 1}
                                            data={data_jam}
                                            
                                          />
                                        );
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </table>

                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
      </>
    );
  }
}

export default Succescheckout;
