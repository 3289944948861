import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
//import { Link } from "react-router-dom";

const ComServices = (props) => {
  return (
    <>
        <div className="col-12 col-md-6 col-lg-6">
            <div className="service-block-1">
                <div className="service-icon-1">
                    <span><i className="fa fa-building"></i></span>
                </div>
                
                <div className="service-text-1">
                    <h3>{props.data.nama}</h3>
                    <p>{props.data.keteranganpendek}</p>
                </div>
            </div>
        </div>
        
      
        
       
    </>
  );
};

export default ComServices;