import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
import Main from "../../layout/Main";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import Comfasilitas from "./Comfasilitas";
import Comfasilitas2 from "./Comfasilitas2";
const images = [
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  },
  {
    original: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg',
    thumbnail: 'https://raw.githubusercontent.com/xiaolin/react-image-gallery/master/static/10.jpg'
  }
]
class PageDetailRuangan extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     errors:false,
  //     list_ruangan: [],
  //     data_ruangan: {},
  //   };
  // }
  constructor(props) {
    super(props);
    const foo = window.location.href;
    const dadiurl1 = window.location.href.split("/");
    const dadiurl = window.location.pathname.split("?");
    var params = new window.URLSearchParams(window.location.search);


    const foo2 = window.location.href.split("?")[1].split('kode');
    this.state = {
      errors:false,
      dadiurl: dadiurl,
      dadiurl1: dadiurl1,
      foo2: foo2,
      hh:{},
      list_fasilitas: [],
      data_fasilitas: {},
      list_gambar: [],
      list_fasilitas2: [],
      data_fasilitas2: {},
      fields: {
        id: "",
        nama_ruang: ""
      },
    };
  }
  list_detail = () => {
  
    fetch("/lmn7/spesifiksdiruang", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "pengajuan_aktehilang",
        id: this.state.dadiurl1[3],
        jns_id: this.state.dadiurl[0],
        foo2: this.state.foo2[1]
        
        
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            hh: data.data[0],
            fields: data.data[0],
            selectedOption: data.data[0].nik,
            list_fasilitas: data.datafasilitas1,
            data_fasilitas: JSON.stringify(data.datafasilitas1),
            list_fasilitas2: data.datafasilitas2,
            data_fasilitas2: JSON.stringify(data.datafasilitas2),
            list_gambar: data.daftargambar
            
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    
  };
  componentDidMount() {
    this.list_detail();
  }

  render(){
    const img=this.state.list_gambar;
    return (
      <>
       <Helmet>
          <title>{this.state.fields["nama_ruang"]}</title>
          <meta name="Keywords" content={this.state.fields["nama_ruang"]}></meta>
        <meta name="Description" content={this.state.fields["nama_ruang"]}></meta>

        </Helmet>
      <Main title="detail ruangan">
        <section className="page-cover style style5 p-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="page-title">Detail Ruangan</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="innerpage-wrapper pt-5 pb-5">
          <div id="hotel-details" className="innerpage-section-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 content-side">
                <ImageGallery items={img} thumbnailPosition="right" showBullets="true"/>
                </div>
              </div>
              <div className="row pt-4">
                <div className="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                  <div className="available-blocks">
                    <h2> {this.state.fields["nama_ruang"]}</h2>
                  </div>
                </div>
                <div className="col-12 col-md-12 col-lg-3 side-bar left-side-bar">
                  <p>Nama Ruangan:</p>
                  <h3 className="text-bold"> {this.state.fields["spek_ruang"]} </h3>

                  <Link to={'/bookingsatu?kode='+this.state.fields["url"]} className="btn btn-block btn-orange mt-4">Booking Sekarang</Link>
                </div>
                <div className="col-12 col-md-12 col-lg-12">
                  <hr/>
                  <div className="mt-3" >
                      <div className="tab-content">
                          <div id="hotel-overview" className="tab-pane in active">
                              <div className="row">
                                  <div className="col-md-4 col-lg-4 tab-text">
                                    <h3>FASILITAS</h3>
                                    <ul>
                                    {this.state.errors === false ? (
                                            this.state.list_fasilitas2.map((data_fasilitas2, i) => {
                                              return (
                                                <Comfasilitas2
                                                key={data_fasilitas2.id}
                                                nom={i + 1}
                                                data={data_fasilitas2}
                                              
                                              />
                                              );
                                            })
                                          ) : (
                                            <>No data</>
                                          )}
                                    </ul>
                                  </div>
                                  
                                  <div className="col-md-8 col-lg-8 tab-text">
                                      <h3>RUANGAN</h3>
                                      <div className="table-responsive">
                                        <table className="table table-striped tnoborder">
                                          <tbody>
                                          {this.state.errors === false ? (
                                            this.state.list_fasilitas.map((data_fasilitas, i) => {
                                              return (
                                                <Comfasilitas
                                                key={data_fasilitas.id}
                                                nom={i + 1}
                                                data={data_fasilitas}
                                              
                                              />
                                              );
                                            })
                                          ) : (
                                            <>No data</>
                                          )}
                                          </tbody>
                                        </table>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> 
      </Main>
      </>
    );
  }
}
export default PageDetailRuangan;

