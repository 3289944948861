import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "../../layout/Main";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
//import $ from "jquery";
import ComGridRuangan from "./ComGridRuangan";
import ComServices from "./ComServices";
import $ from "jquery";

class PageHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors:false,
      list_ruangan: [],
      data_ruangan: {},
      list_service: [],
      data_service: {},
    };
  }

 
  list_detail =data=> {
  
    fetch("lmn7/datahome", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "pengajuan_aktehilang",
        sq:$(".dpd1").val(),
        nlantai:$(".nlantai").val()
         
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
         this.setState({
            list_ruangan: data.data,
            data_ruangan: JSON.stringify(data.data),
            list_service: data.data2,
            data_service: JSON.stringify(data.data2),
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
  };
  componentDidMount(){
    this.list_detail();
  }
  render() {
    const slideImages = [
      '/assets/images/slider/s1.png',
      '/assets/images/slider/s2.png'
     
    ];
    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      prevArrow: <div style={{width: "30px", marginRight: "-30px"}}></div>,
      nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}></div>
    };
    return (
      <Main>
          <HelmetProvider>
            <Helmet>
          
            </Helmet>
          </HelmetProvider>
          <section className="flexslider-container" id="flexslider-container-2">
            <div className="flexslider slider" id="slider-2">
            <Slide {...properties}>
              {slideImages.map((each, index) => (
                <div key={index} className="each-slide">
                  <div style={{ backgroundImage: `url(${each})` }}>
                    <span>IAB</span>
                  </div>
                </div>
              ))}
            </Slide>
            </div>
          </section>
          
          
          <section id="vehicle-offers" className="section-padding">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-heading pb-4">
                    <h2>RUANGAN IAB</h2>
                    <p className="text-dark">
                      Daftar Ruangan IAB
                    </p>
                    <hr className="heading-line" />
                  </div>
                  <div className="row">
                  {this.state.errors === false ? (
                      this.state.list_ruangan.map((data_ruangan, i) => {
                        return (
                          <ComGridRuangan
                            key={data_ruangan.id}
                            nom={i + 1}
                            data={data_ruangan}
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="view-all text-center">
                    <Link to="/ruangan" className="btn btn-orange">
                      lihat semnua
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="best-features"
            className="banner-padding"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-heading pb-4">
                    <h2>LAYANAN IAB</h2>
                    <p className="text-dark">
                      DAFTAR LAYANAN IAB
                    </p>
                    <hr className="heading-line" />
                  </div>
                </div>
                {this.state.errors === false ? (
                      this.state.list_service.map((data_service, i) => {
                        return (
                          <ComServices
                            key={data_service.id}
                            nom={i + 1}
                            data={data_service}
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
              </div>
            </div>
          </section>

          
      </Main>
    );
  }
}

export default PageHome;
