import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
const numberFormat = (value) =>
  new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  }).format(value);
const ComGridhari = (props) => {
    if(props.data.cek==='-'){
        var nceked='';
    }else{
        var nceked='checked';
    }
    if(props.data.cek2!='0'){

  return (
    <>
 <div className="form-check" >
  
    <label className="form-check-label" style={{marginTop:'12px'}}>{props.data.ket} (Terpakai)</label>
</div>
	</>
  );
}else{
  
  return (
    <>
 <div className="form-check" >
    <input type="checkbox"  value={props.data.id}   className="form-check-input waktu " id={props.data.id} name="waktu[]" tanggal={props.data.id}   
        defaultChecked={nceked}  onChange={(e) => props.changefile(e,1)}/>
    <label className="form-check-label" style={{marginTop:'12px'}}>{props.data.ket}</label>
</div>
	</>
  );
}
  
};

export default ComGridhari;
