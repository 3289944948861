import React, { Component } from "react";
import {Redirect, Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "../../layout/Main";
import $ from "jquery";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";

import Comwislist from "./Comwislist";
class Wishlist extends Component {
    constructor(props) {
        super(props);
        const foo = window.location.href;
     
    
        this.state = {
          nkode:"",
          cancel: false,
          notif: null,
          msg: null,
          errors: false,
          btnsimpan:false,
       
        
          fields: {
            id: "",
           pesan: ""
          },
         
          list_ruangan: [],
          data_ruangan: {},
       
         
        };
        
      }
      list_p = () => {
        fetch("public/datawislish", {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            kdd: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].kdd: null,
            nik: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].nik: null,
         }),
          headers: new Headers({
            "content-type": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.kode === "00") {
                this.setState({
                    list_ruangan: data.data,
                    data_ruangan: JSON.stringify(data.data),
                 });
            }
          })
          .catch((error) => {
            this.setState({
              errors: true,
            });
          });
      };
  componentDidMount(){
    this.list_p();
  }
  render() {
      
    return (
      <Main>
        <HelmetProvider>
          <Helmet>
            <title>Wishlist</title>
            <script src="assets/js/bootstrap-datepicker.js"/>
            <script src="assets/js/custom-date-picker.js"/>
          </Helmet>
        </HelmetProvider>
        <div>
        
        
   
        
       
   
        
        
        <section class="innerpage-wrapper">
            <div id="dashboard" class="innerpage-section-padding">
                <div class="container">
                    <div class="row">
                                <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                           
                            
                            <div class="dashboard-wrapper">
                                <div class="row">
                                
                                    <div class="col-12 col-md-2 col-lg-2 dashboard-nav">
                                       
                                    </div>
                                    
                                    <div class="col-12 col-md-8 col-lg-8 dashboard-content wishlist">
                                        <h2 class="dash-content-title">Your Wish List</h2>
                                                <div class="table-responsive">
                                            <table class="table">
                                                <tbody>
                                                {this.state.errors === false ? (
                                                    this.state.list_ruangan.map(
                                                    (data_ruangan, i) => {
                                                        return (
                                                        <Comwislist
                                                            key={data_ruangan.id}
                                                            nom={i + 1}
                                                            data={data_ruangan}
                                                            
                                                        />
                                                        );
                                                    }
                                                    )
                                                ) : (
                                                    <>1</>
                                                )}
                                                    <tr class="list-content">
                                                    <td class="list-text wishlist-text">
                                                       
                                                        
                                                    </td>
                                                    <td class="wishlist-btn d-md-none d-lg-block">
                                                    <Link to="/checkout"  class="btn btn-block btn-orange">
                                          
                                                    CHEKOUT
                                                    </Link>
                                                     </td>
                                                </tr>
                                                 </tbody>
                                             </table>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-2 col-lg-2 dashboard-nav">
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>          
            </div>
        </section>
        
     
        
        
       
        </div>
      </Main>
    );
  }
}

export default Wishlist;
