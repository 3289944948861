import React, { Component } from "react";
import {  Link } from "react-router-dom";
import $ from "jquery";

function openSearch() {
  document.getElementById("myOverlay").style.display = "block";
}
function openDropdown() {
  $("#account").toggle();
}
function closeNavMobile(){
  $("#sidebar").removeClass("active");
  $(".overlay").removeClass("active");
}

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      sessionToken: localStorage.getItem("login_public"),
      out: false,
    };
  }

  //logout
  logout = () => {
    this.setState({
      sessionToken: "",
      out: true,
    });
    localStorage.clear();
  };
  // componentDidMount(){
  //   const current = window.location.pathname
  //   $('#myNavbar1 li a').each(function(){
  //       var $this = $(this);
  //       if($this.attr('href').indexOf(current) !== -1){
  //           $this.addClass('active');
  //       }
  //   })
  // }
  render() {
    return (
      <div>
        <div className="collapse navbar-collapse" id="myNavbar1">
          <ul className="navbar-nav ml-auto navbar-search-link">
            <li className="nav-item">
              <Link  to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link  to="/about" className="nav-link">
                About&nbsp;Us
              </Link>
            </li>
            <li className="nav-item">
              <Link  to="/kegiatan" className="nav-link">
                Berita
              </Link>
            </li>
            <li className="nav-item">
              <Link  to="/ruangan" className="nav-link">
                Ruangan
              </Link>
            </li>
            
            <li className="nav-item">
              <Link  to="/pengaduan" className="nav-link">
              Pengaduan
              </Link>
            </li>
            <li className="nav-item">
              <Link  to="/pemakaian" className="nav-link">
              Terpakai
              </Link>
            </li>
            {this.state.resLogin==='00' ? (<>
           
            <li className="nav-item dropdown">
              <Link  to="#" className="nav-link" data-toggle="dropdown">
                Profil
                <span>
                  <i className="fa fa-angle-down" />
                </span>
              </Link>
              <ul className="dropdown-menu">
                  <li className="nav-item">
                  <Link  to="/services" className="nav-link">
                  Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link  to="/laboratorium" className="nav-link">
                  Laboratorium
                  </Link>
               </li>
               <li className="nav-item">
                <Link  to="/pengajuanku" className="nav-link">
                Pengajuanku
                </Link>
              </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link  to="#" className="nav-link" onClick={this.logout}>
                Logout
              </Link>
            </li>
            </>):(<></>)}
            <li className="dropdown-item search-btn">
             
            </li>
          </ul>
        </div>

        <div className="sidenav-content">
          <nav id="sidebar" className="sidenav">
            <h2 id="web-name">
              <img
                src="logo1.png"
                width="180px"
                height="auto"
                alt="logoweb"
              />
            </h2>
            <div id="main-menu">
              <div id="dismiss">
                <button className="btn" id="closebtn" onClick={() => closeNavMobile()}>
                  ×
                </button>
              </div>
              <div className="list-group panel">
                <Link to="/" className="items-list">
                  <span>
                    <i className="fa fa-home link-icon" />
                  </span>
                  Home
                </Link>
                <Link to="/ruangan" className="items-list">
                  <span>
                    <i className="fa fa-building link-icon" />
                  </span>
                  Ruangan
                </Link>
                <Link  to="/laboratorium" className="items-list">
                  <span>
                    <i className="fa fa-building link-icon" />
                  </span>
                  Laboratorium
                </Link>
                <Link  to="/services" className="items-list">
                  <span>
                    <i className="fa fa-building link-icon" />
                  </span>
                  Services
                </Link>
                {this.state.resLogin==='00' ? (<>
               
                <Link
                  to="#"
                  id="dropdown1"
                  className="items-list"
                  data-toggle="collapse"
                  onClick={() => openDropdown()}
                >
                  <span>
                    <i className="fa fa-user link-icon" />
                  </span>
                  Profil
                  <span>
                    <i className="fa fa-chevron-down arrow" />
                  </span>
                </Link>
                <div className="collapse sub-menu" id="account">
                  <Link to="/account_dashboard" className="items-list">
                    Dashboard
                  </Link>
                  <Link to="/account_profile" className="items-list">
                    User Profile
                  </Link>
                  <Link to="/account_booking" className="items-list">
                    Booking
                  </Link>
                </div>
                <Link
                    to="#"
                    className="items-list"
                    title="Logout"
                    onClick={this.logout}
                  >
                  <span>
                    <i className="fa fa-sign-out link-icon" />
                  </span>
                  Logout
                </Link>
                </>):(<></>)}
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Menu;
