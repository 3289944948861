import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <section id="footer" className="ftr-heading-o ftr-heading-mgn-1">
        <div
          id="footer-top"
          className="banner-padding ftr-top-grey ftr-text-white"
        >
          <div className="container">
            <div className="row">
              <div
                className="
            col-12 col-md-6 col-lg-3 col-xl-3
            footer-widget
            ftr-contact
          "
              >
                <h3 className="footer-heading"></h3>
                <ul className="list-unstyled">
                  <li>
                    <span>
                      <i className="fa fa-map-marker" />
                    </span>
                    Universitas Jenderal Soedirman
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-phone" />
                    </span>
                    (0281) 635292
                  </li>
                  <li>
                    <span>
                      <i className="fa fa-envelope" />
                    </span>
                    iab@unsoed.com
                  </li>
                </ul>
              </div>
              <div
                className="
            col-12 col-md-6 col-lg-4 col-xl-4
            footer-widget
            ftr-links
          "
              >
                <h3 className="footer-heading"></h3>
                <ul className="list-unstyled">
                  <li>
                    <Link to="about">PROFIL</Link>
                  </li>
                  <li>
                    <Link to="ruangan">ruangan</Link>
                  </li>
                  <li>
                    <Link to="pengaduan">pengaduan</Link>
                  </li>
                
                </ul>
              </div>
              <div
                className="
            col-12 col-md-6 col-lg-5 col-xl-5
            footer-widget
            ftr-about
          "
              >
                <h3 className="footer-heading"></h3>
                <p>
                Universitas Jenderal Soedirman
                </p>
                <ul className="social-links list-inline list-unstyled">
                  <li className="list-inline-item">
                    <Link to="#">
                      <span>
                        <i className="fa fa-facebook" />
                      </span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="about">
                      <span>
                        <i className="fa fa-twitter" />
                      </span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <span>
                        <i className="fa fa-instagram" />
                      </span>
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="#">
                      <span>
                        <i className="fa fa-youtube-play" />
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id="footer-bottom" className="ftr-bot-black">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 col-xl-6" id="copyright">
                <p>
                  © 2022 Universitas Jenderal Soedirman
                </p>
              </div>
              <div className="col-12 col-md-6 col-lg-6 col-xl-6" id="terms">
               
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
