import { data } from "jquery";
import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Compakai = (props) => {
  let nama="detailruangan?kode="+props.data.kode;
  return (
    <>
    
                      <tr>
                            <td  width="20%">{props.data.tgl_pakai}</td>
                            <td  width="30%">{props.data.namaruangan}</td>
                            <td  width="50%">{props.data.jam} </td>
                           
                          </tr>
                     

     
    </>
  );
};

export default Compakai;
