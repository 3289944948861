import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
var sum1=0;

const Comwislist = (props) => {
  let nama="detailruangan?kode="+props.data.kode;
  sum1 +=props.data.total;
  if(props.data.lvl=='2'){
    return (
      <>
        <tr class="list-content">
          <td class="list-text wishlist-text">
           </td>
           <td class="list-text wishlist-text">
           </td>
           <td class="list-text wishlist-text">
              <h3>TOTAL:  {props.data.total}</h3>
           </td>
        
      </tr>
  
  
       
      </>
    );

  }else{
    return (
      <>
        <tr class="list-content">
          <td class="list-text wishlist-text">
              <h3>{props.data.nama}:{props.data.jns_nm} x {props.data.qty}</h3>
                 
              
              </td>
          <td class="list-text wishlist-text">
                 
              
              <p>Tanggal: {props.data.tgl1} Pukul {props.data.jammulai} </p>
             
              <p class="order"><span>Order Total:</span> Rp.  {props.data.total} </p>
          </td>
          <td class="wishlist-btn d-md-none d-lg-block"><button class="btn btn-lightgrey">Remove</button></td>
      </tr>
  
  
       
      </>
    );
  }
 

};

export default Comwislist;
