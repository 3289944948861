import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import {
  PageHome,
  PageRuangan,
  PagePembayaran,
  PageLogin,
  PageSignup,
  PageAcountDashboard,
  PageAcountProfile,
  PageAcountBooking,
  PageCheckout,
  Pagelogout,
  PageAbout,
  PagePengaduan,
  PageServices,
  PageLaboraturium,
  PageDashboardtv,
  PagePemakaian,
  PagePengajuanku,
  PageReset
} from "./pages";
import PageDetailRuangan from "./pages/ruangan/Detail";
import PageBooking from "./pages/ruangan/bookingsatu";
import PageBooking2 from "./pages/ruangan/bookingdua";
//import DetailRuangan from "./pages/ruangan/DetailRuangan";
import SuccessPayment from "./pages/pembayaran/SuccessPayment";
import Wishlist from "./pages/pembayaran/Wishlist";
import Successcechkout from "./pages/checkout/Succescheckout";
import Validasiresetakun from "./pages/reset/Validasiresetakun";
import PageBerita from "./pages/berita/PageBerita";
import Detailberita from "./pages/berita/Detailberita";
import Dasboardtv2 from "./pages/dashboardtv/Dasboardtv2";

class Routes extends Component {
  
  render() {
    return (
      <Switch>
         <Route sensitive  path="/kegiatan" component={PageBerita}>
          <PageBerita />
        </Route>
        <Route sensitive  path="/detailberita" component={Detailberita}>
          <Detailberita />
        </Route>
        <Route sensitive  exact path="/tv1" component={Dasboardtv2}>
          <Dasboardtv2 />
        </Route>
        <Route sensitive  exact path="/tvdasboard" component={PageDashboardtv}>
          <PageDashboardtv />
        </Route>
        <Route sensitive  exact path="/dasboardtv" component={PageDashboardtv}>
          <PageDashboardtv />
        </Route>
        <Route sensitive  exact path="/pemakaian" component={PagePemakaian}>
          <PagePemakaian />
        </Route>
        <Route sensitive  exact path="/pengajuanku" component={PagePengajuanku}>
          <PagePengajuanku />
        </Route>
        <Route sensitive  exact path="/" component={PageHome}>
          <PageHome />
        </Route>
        <Route sensitive  path="/detailruangan" component={PageDetailRuangan}>
          <PageDetailRuangan />
        </Route>
        <Route sensitive  path="/bookingsatu" component={PageBooking}>
          <PageBooking />
        </Route>
        <Route sensitive  path="/bookingdua" component={PageBooking2}>
          <PageBooking2 />
        </Route>
        <Route sensitive  path="/bookingtiga" component={PageBooking2}>
          <PageBooking2 />
        </Route>
        <Route sensitive  path="/bookingempat" component={PageBooking2}>
          <PageBooking2 />
        </Route>
        <Route sensitive  path="/bookinglima" component={PageBooking2}>
          <PageBooking2 />
        </Route>
        <Route sensitive  path="/ruangan" component={PageRuangan}>
          <PageRuangan />
        </Route>
        <Route sensitive  path="/laboratorium" component={PageLaboraturium}>
          <PageLaboraturium />
        </Route>
        <Route sensitive  path="/pembayaran" component={PagePembayaran}>
          <PagePembayaran />
        </Route>
        <Route sensitive  path="/checkout" component={PageCheckout}>
          <PageCheckout />
        </Route>
        <Route sensitive  path="/successcechkout" component={Successcechkout}>
          <Successcechkout />
        </Route>
        <Route sensitive  path="/wishlist" component={Wishlist}>
          <Wishlist />
        </Route>
        <Route sensitive  path="/login" component={PageLogin}>
          <PageLogin />
        </Route>
        <Route sensitive  path="/resetakun" component={PageReset}>
          <PageReset />
        </Route>
        <Route sensitive  path="/validasiresetakun" component={Validasiresetakun}>
          <Validasiresetakun />
        </Route>
        
        <Route sensitive  path="/logout" component={Pagelogout}>
          <Pagelogout />
        </Route>
        <Route sensitive  path="/signup" component={PageSignup}>
          <PageSignup />
        </Route>
      

        <Route sensitive  path="/services" component={PageServices}>
          <PageServices />
        </Route>
        <Route sensitive  path="/account_dashboard" component={PageAcountDashboard}>
          <PageAcountDashboard />
        </Route>
        <Route sensitive  path="/account_profile" component={PageAcountProfile}>
          <PageAcountProfile />
        </Route>
        <Route sensitive  path="/account_booking" component={PageAcountBooking}>
          <PageAcountBooking />
        </Route>
        <Route sensitive  path="/success_payment" component={SuccessPayment}>
          <SuccessPayment />
        </Route>
        <Route sensitive  path="/about" component={PageAbout}>
          <PageAbout />
        </Route>
        <Route sensitive  path="/pengaduan" component={PagePengaduan}>
          <PagePengaduan />
        </Route>
      </Switch>
    );
  }
}

export default Routes;
