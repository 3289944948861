import React, { Component } from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";
import $ from "jquery";

function openSearch() {
  document.getElementById("myOverlay").style.display = "block";
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionToken: localStorage.getItem("login_public"),
      out: false,
    };
  }

  //logout
  logout = () => {
    this.setState({
      sessionToken: "",
      out: true,
    });
    localStorage.clear();
  };

  componentDidMount(){
    $("#dismiss, .overlay").on("click", function () {
      $("#sidebar").removeClass("active");
      $(".overlay").removeClass("active");
    });
  
    $("#sidebarCollapse").on("click", function () {
      $("#sidebar").addClass("active");
      $(".overlay").addClass("active");
      $(".collapse.in").toggleClass("in");
      $("a[aria-expanded=true]").attr("aria-expanded", "false");
    });
  }

  render() {
    return (
      <div>
        <div id="top-bar" className="tb-text-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <div id="info">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <span>
                        <i className="fa fa-map-marker" />
                      </span>
                     UNIVERSITAS JENDRAL SOEDIRMAN
                    </li>
                    <li className="list-inline-item">
                      <span>
                        <i className="fa fa-phone" />
                      </span>
                      (0281) 635292
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div id="links">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <Link to="/login">
                        <span>
                          <i className="fa fa-user" />
                        </span>
                        Login
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/signup">
                        <span />
                        Sign Up
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="/resetakun">
                        <span />
                       Lupa Password
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          className="navbar navbar-expand-xl sticky-top navbar-custom main-navbar p-1"
          id="mynavbar-1"
        >
          <div className="container">
            <Link to="/" className="navbar-brand py-1 m-0">
              <img
                src="logo1.png"
                width="100%"
                height="45px"
                alt="logoweb"
              />
            </Link>
            <div className="header-search d-xl-none my-auto ml-auto py-1">
              <Link to="#" className="search-button" onClick={openSearch}>
                <span>
                  <i className="fa fa-search" />
                </span>
              </Link>
            </div>
            <button
              className="navbar-toggler ml-2"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="sidebarCollapse"
            >
              <i className="fa fa-navicon py-1" />
            </button>
            <Menu />
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
