import React, { Component } from "react";
import { Link } from "react-router-dom";


import Main from "../../layout/Main";

class SuccessPayment extends Component {
  render() {
    return (
      <Main>
        <section className="page-cover style style5 p-20">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h1 className="page-title">Pembayaran Berhasil</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="innerpage-wrapper">
          <div id="thank-you" className="innerpage-section-padding">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12 content-side">
                  <div className="space-right">
                    <div className="thank-you-note">
                      <h3>Your Booking is confirmed now. Thank You!</h3>
                      <p>
                        Lorem ipsum dolor sit amet, conse adipiscing elit
                        curabitur.
                      </p>
                      <Link to="#" className="btn btn-orange">
                        <i className="fa fa-print" /> Print Details
                      </Link>
                      <Link to="#" className="btn btn-dark">
                        {" "}
                        Cancel
                      </Link>
                    </div>
                  </div>
                  <div className="traveler-info">
                    <h3 className="t-info-heading">
                      <span>
                        <i className="fa fa-info-circle" />
                      </span>
                      Payment Information
                    </h3>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>Booking Number:</td>
                            <td>000-111-222</td>
                          </tr>
                          <tr>
                            <td>Tanggal Bayar :</td>
                            <td>30-10-2021</td>
                          </tr>
                          <tr>
                            <td>Nama Pengirim :</td>
                            <td>James</td>
                          </tr>
                          <tr>
                            <td>Nama Pemegang Rekening :</td>
                            <td>James</td>
                          </tr>
                          <tr>
                            <td>No Rekening :</td>
                            <td>12345</td>
                          </tr>
                          <tr>
                            <td>Email Address:</td>
                            <td>info@loremipsum.com</td>
                          </tr>
                          <tr>
                            <td>Metode Transfer:</td>
                            <td>ATM</td>
                          </tr>
                          <tr>
                            <td>Telah Bayar ke Rekening:</td>
                            <td>6393-01-006092-53-5 an Mochtar Kelana</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <Link to="#" className="btn btn-orange">
                    <i className="fa fa-print" /> Print Details
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Main>
    );
  }
}

export default SuccessPayment;
