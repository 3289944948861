import React, { Component } from "react";
import {Redirect, Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Main from "../../layout/Main";
import $ from "jquery";
//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";
import Comtemp from "./Comtemp";

class PagePembayaran extends Component {
constructor(props) {
        super(props);
        const foo = window.location.href;
        var msgotp2="";
    this.state = {
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      datLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0]: null,
      sessionToken: localStorage.getItem("login_public"),
      kodeotp2:"",
        show: false,
        loadpengajuan:false,
        loadingAct: false,
        loadingbtn: false,
        notif: null,
        msg: null,
        msgotp: null,
        errors: false,
        btnsimpan:false,
        nkode:"",
        foo: foo,
        fields: {
          id: "",
          nama: "",
          tagihan:"",
          norek:""
        },
        list_byr: [],
        data_byr: {},
        list_temp: [],
        data_temp: {},
        hh:{},
      };
    }
    handleSubmit = (e) => {
       
        fetch("public/konfirmasibayar", {
          method: "POST",
          mode: "cors",
          body: new FormData(e.target),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.kode === "00") {
              this.setState({
                loadingAct: true,
                notif: true,
               
                msg: data.pesan,
              });
              //loading
              setTimeout(() => {
               this.setState({
                  loadpengajuan:true,
                  notif: true,
                  loadingAct: true,
                  nkode:"/checkout?kode"+data.nkode,
                  msg: data.pesan,
                  btnsimpan:false
                });
                //reset form
               
               // this.notif();
              }, 22000);
            } else {
              this.setState({
                loadingAct: true,
                notif: true,
                msg: data.pesan,
                btnsimpan:false
              });
             
            }
          })
          .catch((error) => {
            this.setState({
              errors: true,
            });
          });
        e.preventDefault();
      };
    
      handleChange = (field, e) => {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
        console.log(this.state.fields);
      }; 
      handleClick = () => {
        this.setState({
          loadingbtn:true
         });
        fetch("public/kirimotp", {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            jns_id: "pengajuan_perubahandata",
            nik:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].nik: null,
            kdd:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].kdd: null,
            kodeopt:$("#kodeotp2").val()
          
          }),
          headers: new Headers({
            "content-type": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.kode === "00") {
                this.setState({
                  loadingbtn:false,
                  msgotp: "kode berhasil di kirim ke Nomor Watshap"
                 });
                 setTimeout(() => {
                  this.setState({
                    msgotp:""
                   
                  });
                  //reset form
                 
                }, 4000);
            }
          })
          .catch((error) => {
            this.setState({
              errors: true,
            });
          });
      };
    list_p = () => {
        fetch("public/datacheckout", {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({
            url: this.state.foo,
            jns_id: "pengajuan_perubahandata",
            nik:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].nik: null,
            kdd:localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].kdd: null,
        
          }),
          headers: new Headers({
            "content-type": "application/json",
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.kode === "00") {
                this.setState({
                    list_byr: data.data,
                    data_byr: JSON.stringify(data.data),
                    list_temp: data.datatemp,
                    data_temp: JSON.stringify(data.datatemp),
                    kodeotp2:Math.floor(Math.random() * 1100),
                    fields: data.data3[0]
                 });
            }
            if (data.kode3 === "00") {
              this.setState({
                hh: data.data3[0],
              });
              //console.log(this.state.data_anggotaIndividu);
            }
          })
          .catch((error) => {
            this.setState({
              errors: true,
            });
          });
      };
  handleChange = (field, e) => {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    fields["nikinput"] = this.state.datLogin.nik;
    this.setState({ fields });
    console.log(this.state.fields);
  };
  componentDidMount(){
    this.list_p();
  }
  render() {
    const {loadingAct,nkode,resLogin } = this.state;
     let style="";
      let htmlbtn='Kirim OTP';
      let dsbled='';
      let nn="/successcechkout?kode=";
      
    if (this.state.loadingbtn === true) {
      htmlbtn="...Loading";
      dsbled="disabled";
    }
    if (this.state.loadingAct === true) {
     // return <Redirect to={this.state.nkode} />;
    }
    if (resLogin !== "00") {
      return <Redirect to="/login" />;
    }
    return (
      <Main>
        <HelmetProvider>
          <Helmet>
            <title>Chekout</title>
            <script src="assets/js/bootstrap-datepicker.js"/>
            <script src="assets/js/custom-date-picker.js"/>
          </Helmet>
        </HelmetProvider>
        <div>
          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">KONFIRMASI PEMBAYARAN</h1>
                </div>
              </div>
            </div>
          </section>
          <section className="innerpage-wrapper" >
            <div id="cruise-booking" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
                       <div className="col-12 col-md-12 col-lg-5 col-xl-2 side-bar right-side-bar">
                          
                        
                          </div>
                        <div className="col-12 col-md-12 col-lg-7 col-xl-8 content-side">
                            <form className="lg-booking-form" id="frmcheckout" onSubmit={this.handleSubmit}>
                            <input
                            type="hidden"
                            name="kodedd"
                            value={localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].kdd: null}
                            />
                                <div className="lg-booking-form-heading">
                                  
                                  <h3><b>{this.state.msg}</b></h3>
                              </div>
                                <input type="hidden" name="kdd" id="kdd" className="form-control" />
                                   
                                <div className="personal-info">
                                
                                    <div className="row">
                                       <div className="col-2 col-md-3"><label>Nama</label></div>
                                       <div className="col-4 col-md-3">
                                       
                                            <div className="form-group">
                                               
                                                <input type="text" readonly name="nama" onChange={this.handleChange.bind(
                                          this,
                                          "nama"
                                        )}
                                        value={this.state.fields["nama"]} className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="col-2 col-md-3"><label>NIK</label></div>
                                        <div className="col-4 col-md-3">
                                            <div className="form-group">
                                                <input type="text" readonly name="nik" value={this.state.hh.nik?this.state.hh.nik:""}  className="form-control" required/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="row">
                                    <div className="col-2 col-md-3"><label>No Hp</label></div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                 <input type="text" readonly name="nohp" value={this.state.hh.nohp?this.state.hh.nohp:""}  className="form-control" required/>
                                            </div>
                                        </div>
                                        <div className="col-2 col-md-3"><label>Email</label></div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <input type="text" readonly name="email" value={this.state.hh.email?this.state.hh.email:""}  className="form-control" required/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                    <div className="col-2 col-md-3"><label>Nor Rek Anda</label></div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                               <input type="text" readonly name="norek" onChange={this.handleChange.bind(this,"norek")}
                                            value={this.state.fields["norek"]}  className="form-control" required/>
                                            </div>
                                        </div>
                                      <div className="col-2 col-md-3"><label>Upload Bukti</label></div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                               <input type="file"  name="nfile"   className="form-control" required/>
                                            </div>
                                        </div>  
                                    </div>
                                    <div className="row">
                                    <div className="col-2 col-md-3"><label>Metode Pembayaran</label></div>
                                        <div className="col-md-9">
                                            <div className="form-group">
                                            <select
                                                className="form-control "
                                                name="metode"
                                                aria-invalid="false"
                                                >
                                               
                                                {this.state.list_byr.map(
                                                    (data_byr, i) => {
                                                        return (
                                                            <option
                                                                key={data_byr.id}
                                                                value={data_byr.kode}
                                                            >
                                                                {data_byr.kode} -
                                                                {data_byr.nama} -
                                                                {data_byr.keterangan}
                                                            </option> 
                                                            );

                                                    

                                                    
                                                    }
                                                )}
                                                </select>
                                                
                                            </div>
                                        </div>
                                       
                                    </div>   
                                </div>
                              
                               <div className="row">
                                  <div className="col-4 col-md-4"></div>
                                  <div className="col-8 col-md-8">{this.state.msgotp}</div>
                               </div>
                                <div className="row">
                                    <div className="col-8 col-md-8">
                                    <div className="checkbox">
                                        <label><input type="checkbox"/> By continuing, you are agree to the <a href="#">Terms and Conditions.</a></label>
                                    </div>

                                    </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                            <button type="submit" className="btn btn-orange">Confirm Booking</button>
                                            </div>
                                        </div>
                                       
                                    </div> 
                                        
                               
                                  
                               
                            </form>
                            
                        </div>

                        <div className="col-12 col-md-12 col-lg-5 col-xl-2 side-bar right-side-bar">
                          
                        
                        </div>
                        
        
                    </div>
                </div>         
            </div>
        </section>
        </div>
      </Main>
    );
  }
}

export default PagePembayaran;
