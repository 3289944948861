import React, { Component } from "react";
import { Link,Redirect } from "react-router-dom";
import Main from "../../layout/Main";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

class Bookingsatu extends Component {
  constructor() {
    super();
    const foo = window.location.href;
    const dadiurl1 = window.location.href.split("/");
    const dadiurl = window.location.pathname.split("?");
    var params = new window.URLSearchParams(window.location.search);
    let foo2 = params.get('kode')
    let idd = params.get('idd')

    //const foo2 = window.location.href.split("?")[1].split('kode');

    this.state = {
      resLogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).kode: null,
      datalogin: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0]: null,
      usersmp: "ttdatakeluarga",
      dadiurl: dadiurl,
      dadiurl1: dadiurl1,
      foo2: foo2,
      idd: idd,
      pwd: "",
      pindahhalaman:0,
      datakosong:1,
      kodepindahhalaman:"",
      fields: {
        id_pemohon:"",
        nama: "",
        unit: "",
        alamat: "",
        nohp: "",
        email: "",
        tgl_pakai: "",
        tgl_selesai: "",
        nama_ruang:"",
       
      },
      fields2: {
        pemohon: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].nama: null,
        
      
        unit: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].unit: null,
        alamat: "",
        nohp: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].nohp: null,
        email: localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].email: null,
        tgl_pakai: "",
        tgl_selesai: "",
       
        tgl_masuk:"s",
         tgl_selesai:""
      },
      tgl_masuk:new Date(),
      tgl_selesai:new Date(),
      loadingAct: false,
      errors: {},
      notif: null,
      notif2:null
    };
  }

  loadingAction = () =>
    this.state.loadingAct === true ? (
      <i className="fa fa-spinner fa-spin"></i>
    ) : (
      <div></div>
    );

  notif = () => {
    if (this.state.notif === true) {
      return(
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <strong>{this.state.notif2}</strong>
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      ); 
    } else if (this.state.notif === false) {
      return (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <strong>Booking Gagal !</strong> {this.state.notif2}
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      );  
    } else {
      <></>;
    }
  }

  handleValidation() {
    let fields = this.state.fields2;
    let errors = {};
    let formIsValid = true;
    //for huruf match(/^[a-zA-Z]+$/)

    //nama
    if (!fields["pemohon"]) {
      formIsValid = false;
      errors["pemohon"] = "Cannot be empty";
    }
    //lembaga
    if (!fields["unit"]) {
      formIsValid = false;
      errors["unit"] = "Cannot be empty";
    }
    //alamat
    if (!fields["alamat"]) {
      formIsValid = false;
      errors["alamat"] = "Cannot be empty";
    }

    //NOHP
    if (!fields["nohp"]) {
      formIsValid = false;
      errors["nohp"] = "Cannot be empty";
    }

    if (typeof fields["nohp"] !== "undefined") {
      if (!fields["nohp"].match(/^-?[0-9]\d*\.?\d*$/)) {
        formIsValid = false;
        errors["nohp"] = "Only Number";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  bookingSubmit(e) {
    e.preventDefault();
    console.log(this.state.fields);
    let fields = this.state.fields;
    if (this.handleValidation()) {
      fetch("lmn7/bookingsatu", {
        method: "POST",
        mode: "cors",
        body: new FormData(e.target)
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.kode === "00") {
           
            this.setState({
              loadingAct: true,
              notif: true,
              notif2: data.pesan,
              pindahhalaman:1,
              kodepindahhalaman:"bookingdua?kode="+data.nkode+"&tahap=1",
            });
          } else {
            this.setState({
              loadingAct: true,
              notif: false,
              notif2: data.pesan,
            });
          }
          setTimeout(() => {
           
            this.setState({
              notif2: ""
           
            });
            //reset form
           
            this.notif();
          }, 2000);
          //loading
        })
        .catch((error) => {
          this.setState({
            errors: true,
          });
        });
    } else {
      
    }
  }
  list_detail = () => {
  
    fetch("/lmn7/namaruang", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "bookingsatu",
        id: this.state.dadiurl1[3],
        jns_id: this.state.dadiurl[0],
        foo2: this.state.foo2,
        idd: this.state.idd
     }),
      headers: new Headers({
        "content-type": "application/json",

      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.kode === "00") {
          this.setState({
            fields: data.data[0],
            selectedOption: data.data[0].nik,
            list_fasilitas: data.datafasilitas1,
            data_fasilitas: JSON.stringify(data.datafasilitas1),
           
          });
        }else{
          this.setState({
            datakosong:2
          });
          
        }
        if (data.kode2 === "00") {
          this.setState({
            fields2: data.data2[0],
          });
        }
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
    
  };
  componentDidMount() {
    this.list_detail();
  }
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
    
  }
  handleChange2(field, e) {
    let fields2 = this.state.fields2;
    fields2[field] = e.target.value;
    this.setState({ fields2 });
    
  }
  render() {
    const {resLogin, pindahhalaman,kodepindahhalaman,datakosong} = this.state;
    if(pindahhalaman===1){
      return <Redirect to={kodepindahhalaman} />;
    }
 
    if (datakosong === 2) {
      return <Redirect to="/ruangan" />;
    }
    if (resLogin !== "00") {
      return <Redirect to="/login" />;
    }

    return (
      <>
<Main title="booking">
  <section className="page-cover style style5 p-20">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h1 className="page-title">Booking Tahap 1 dari 3</h1>
        </div>
      </div>
    </div>
  </section>
  <section className="innerpage-wrapper pt-5 pb-5">
    <form onSubmit={this.bookingSubmit.bind(this)} id="hotel_frm_booking" name="hotel_frm_booking">
      <div id="hotel-details" className="innerpage-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2 side-bar right-side-bar">
              <div className="side-bar-block booking-form-block">
                <div className="booking-form">
                  <div className="col-md-11 offset-md-1 side-bar ">
                    <div className="side-bar-block booking-form-block">
                      <div className="row">
                        <label className="col-md-3 offset-md-12 side-bar  ">Nama Ruangan</label>
                        <input type="hidden" className="form-control" placeholder="kode " name="kd_ruang" value={this.state.foo2} />
                        <input type="hidden" className="form-control" placeholder="idd " name="idd" value={this.state.idd} />
                        <input type="hidden" className="form-control"  name="id_pemohon" value={localStorage.getItem("login_public")? JSON.parse(localStorage.getItem("login_public")).data[0].id_pemohon: null} />
                        <small style={{ color: "red" }}> {this.state.errors["kd_ruang"]} </small>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control"  name="nama_ruangan"  value={this.state.fields["nama_ruang"]} />
                          <small style={{ color: "red" }}> {this.state.errors["nama_ruang"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">Nama </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control" name="pemohon" onChange={this.handleChange2.bind(this, "pemohon" )} value={this.state.fields2["pemohon"]} />
                          <small style={{ color: "red" }}> {this.state.errors["pemohon"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">Unit </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control"  name="unit" onChange={this.handleChange2.bind(this, "unit" )} value={this.state.fields2["unit"]} />
                          <small style={{ color: "red" }}> {this.state.errors["naunitma"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">Alamat </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <textarea type="text" className="form-control" name="alamat" onChange={this.handleChange2.bind(this, "alamat" )}>{this.state.fields2["alamat"]}</textarea>
                          <small style={{ color: "red" }}> {this.state.errors["alamat"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">Nama Kegiatan </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control"  name="namakegiatan" onChange={this.handleChange2.bind(this, "namakegiatan" )} value={this.state.fields2["namakegiatan"]} />
                          <small style={{ color: "red" }}> {this.state.errors["namakegiatan"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">NoHp </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control"  name="nohp" onChange={this.handleChange2.bind(this, "nohp" )} value={this.state.fields2["nohp"]} />
                          <small style={{ color: "red" }}> {this.state.errors["nohp"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">Email </label>
                        <div className="col-md-9 offset-md-12 side-bar ">
                          <input type="text" className="form-control"  name="email" onChange={this.handleChange2.bind(this, "email" )} value={this.state.fields2["email"]} />
                          <small style={{ color: "red" }}> {this.state.errors["email"]} </small>
                        </div>
                      </div>
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">File Rundown </label>
                        <div className="col-md-8 offset-md-12 side-bar ">
                          <input type="file" className="form-control" name="file1" />
                          <small style={{ color: "red" }}> {this.state.errors["file1"]} </small>
                        </div>
                      </div> 
                      <div className="row">
                        <label className="col-md-3 offset-md-12  ">File Permohonan </label>
                        <div className="col-md-8 offset-md-12 side-bar ">
                          <input type="file" className="form-control"  name="file2" />
                          <small style={{ color: "red" }}> {this.state.errors["file1"]} </small>
                        </div>
                      </div> 
                       < div className="row">
                        <label className="col-md-3 offset-md-12 side-bar  ">Tgl Pakai</label>
                        <div className="col-md-3 offset-md-12 side-bar ">
                          <div className="form-group"> 
                            <DatePicker selected={ this.state.tgl_masuk } onChange={(date)=>this.setState({tgl_masuk: date})} name="tgl_masuk" dateFormat="dd-MM-yyyy"   className="form-control" placeholder="Tanggal Masuk" />
                          </div>
                        </div>
                        <label className="col-md-3 offset-md-12 side-bar  ">Tgl Selssai</label>
                        <div className="col-md-3 offset-md-12 side-bar ">
                          <div className="form-group">
                            <DatePicker selected={ this.state.tgl_selesai } onChange={(date)=>this.setState({tgl_selesai: date})} name="tgl_selesai" dateFormat="dd-MM-yyyy" className="form-control" placeholder="Tanggal Masuk" />
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 offset-md-6 side-bar ">{this.state.notif2}</div>
                <button className="btn btn-block btn-orange mt-4" type="submit"> {this.loadingAction()} Selanjutnya </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </form>
  </section>
</Main>
      </>
    )
  }
}
export default Bookingsatu;

