import { data } from "jquery";
import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Compakai = (props) => {
  var img1="";
  var img2="";
  var img3="";
  var img4="";
  var img5="";
  var img6="";
  var img7="";
  var img8="";
  var img9="";
  var img10="";
  var img11="";
  var img12="";
  var img13="";
  var img14="";

  if(props.data.jam1!=='-'){
    var img1="images/ceklis.png";
  }
  if(props.data.jam2!=='-'){
    var img2="images/ceklis.png";
  }
  if(props.data.jam3!=='-'){
    var img3="images/ceklis.png";
  }
  if(props.data.jam4!=='-'){
    var img4="images/ceklis.png";
  }
  if(props.data.jam5!=='-'){
    var img5="images/ceklis.png";
  }
  if(props.data.jam6!=='-'){
    var img6="images/ceklis.png";
  }
  if(props.data.jam7!=='-'){
    var img7="images/ceklis.png";
  }
  if(props.data.jam8!=='-'){
    var img8="images/ceklis.png";
  }
  if(props.data.jam9!=='-'){
    var img9="images/ceklis.png";
  }
  if(props.data.jam10!=='-'){
    var img10="images/ceklis.png";
  }
  if(props.data.jam11!=='-'){
    var img11="images/ceklis.png";
  }
  if(props.data.jam1!=='-'){
    var img12="images/ceklis.png";
  }
  if(props.data.jam12!=='-'){
    var img13="images/ceklis.png";
  }
  if(props.data.jam13!=='-'){
    var img14="images/ceklis.png";
  }
 
  return (
    <>
     <tr>
      <td>{props.data.tanggal}</td>
      <td>{props.data.namaruangan}</td>
      <td><img  src={img1} height="15x"/></td>
      <td><img  src={img2} height="15x"/></td>
      <td><img  src={img3} height="15x"/></td>
      <td><img  src={img4} height="15x"/></td>
      <td><img  src={img5} height="15x"/></td>
      <td><img  src={img6} height="15x"/></td>
      <td><img  src={img7} height="15x"/></td>
      <td><img  src={img8} height="15x"/></td>
      <td><img  src={img9} height="15x"/></td>
      <td><img  src={img10} height="15x"/></td>
      <td><img  src={img11} height="15x"/></td>
      <td><img  src={img12} height="15x"/></td>
      <td><img  src={img13} height="15x"/></td>
      <td><img  src={img14} height="15x"/></td>
  
   
      
    </tr>

     
    </>
  );
};

export default Compakai;
