import { data } from "jquery";
import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Comfasilitas = (props) => {
  let nama="detailruangan?kode="+props.data.kode;
  return (
    <>
     <tr>
      <td>{props.data.nama}</td>
      <td>{props.data.keterangan}</td>
    </tr>

     
    </>
  );
};

export default Comfasilitas;
