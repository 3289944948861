import { data } from "jquery";
import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Compakai = (props) => {
  var img1="";
  var dwn="";
  var dwn1="";
  var dwn2="";
  var dwn3="";


  if(props.data.jam1!=='-'){
    var img1="images/ceklis.png";
  }
  if(props.data.nfile!=''){
    dwn= <a href={props.data.nfile} target="_blank">download</a>;
  }
  if(props.data.nfile1!=''){
    dwn1= <a href={props.data.nfile1} target="_blank">download</a>;
 }
  if(props.data.nfile2!=''){
    dwn2= <a href={props.data.nfile2} target="_blank">download</a>;
 }
 if(props.data.nfile3!=''){
    dwn3= <a href={props.data.nfile3} target="_blank">download</a>;
  }
  return (
    <>
     <tr>
      <td>{props.data.tgl_pakai}</td>
      
      <td>{props.data.namaruangan}</td>
      <td>{props.data.nama}</td>
      <td>{props.data.nohp}</td>
      <td>{props.data.nstatus}</td>
      <td>{props.data.alasantolak}</td>
      <td>{dwn}</td>
      <td>{dwn1}</td>
      <td>{dwn2}</td>
      <td>{dwn3}</td>
      
      <td className="text-left">
        <Link to={"bookingsatu?idd="+props.data.idd+"&kode="+props.data.ruangan} >edit</Link>
      </td>
   
      
    </tr>

     
    </>
  );
};

export default Compakai;
