import React, { Component } from "react";
import { Link } from "react-router-dom";
import Main from "../../layout/Main";

import ComGridBerita from "./ComGridBerita";
import Pagination from './Pagination';

//import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
//import axios from "axios";
import $ from "jquery";

class PageBerita extends Component {
  constructor(props) {
    super(props);
    const search = window.location.search;
    var segments = window.location.href.split("/");
    const params = new URLSearchParams(search);
    const foo = params.get('page');
    const secondLastSegment = segments[segments.length - 1];

    this.state = {
      jns: $("#njns").val(),
      //jns: 1,
      cancel: false,
      notif: null,
      msg: null,
      errors: false,
      btnsimpan: false,
      dd: secondLastSegment,
      foo:foo,
      jmlhalaman:0,
      fields: {
        id: "",
        pesan: "",
      },

      list_ruangan: [],
      data_ruangan: {},
      allCountries: [], currentCountries: [], currentPage: null, totalPages: null
    };
  }
  handleClick = () => {
    this.list_p(1);
  };
  list_p =data=> {
    const { currentPage, totalPages, pageLimit } = data;
   
    fetch("lmn7/databerita", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        url: "11",
        jns_id: "pengajuan_aktehilang",
        sq:$(".dpd1").val(),
        nlantai:$("#nlantai").val(),
        hmln:currentPage
         
      }),
      headers: new Headers({
        "content-type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        //if (data.kode === "00") {
        
          this.setState({
            jmlhalaman:data.total,
            list_ruangan: data.data,
            data_ruangan: JSON.stringify(data.data),
          });
        //}
      })
      .catch((error) => {
        this.setState({
          errors: true,
        });
      });
   
  };
  componentDidMount() {
    this.list_p(1);
    // const { data: allCountries = [] } = this.state.list_ruangan;
    // this.setState({ allCountries });
  }
  render() {
    //const { allCountries, currentCountries, currentPage, totalPages } = this.state.data_ruangan;
    const totalCountries = this.state.jmlhalaman;
    var jenis=$("#njns").val();
    //if (totalCountries === 0) return null;

   
    return (
      <Main title="Ruangan">
        <div>
          <section className="page-cover style style5 p-20">
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-center">
                  <h1 className="page-title">KEGIATAN IAB</h1>
                </div>
              </div>
            </div>
          </section><br/>
          <section className="innerpage-wrapper pb-5 pt-5">
            <div id="hotel-listing" className="innerpage-section-padding">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 col-xl-12 content-side">
                    <div className="row">
                      <div className="col-md-12 side-bar-block support-block">
                        <div className="row">
                         

                          <div className="col-12 col-md-12 col-lg-12 col-xl-9">
                            <div className="row">
                              <div className="col-12 col-md-12 col-lg-12">
                                <div className="form-group left-icon">
                                  <input
                                    type="text"
                                    className="form-control dpd1"
                                    placeholder="Nama Ruangan"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-md-12 col-lg-12 col-xl-2 search-btn">
                            <button
                              className="btn btn-orange"
                              onClick={this.handleClick}
                            >
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                    <>
                          {this.state.errors === false ? (
                            this.state.list_ruangan.map((data_ruangan, i) => {
                              return (
                                <ComGridBerita
                                  key={data_ruangan.id}
                                  nom={i + 1}
                                  data={data_ruangan}
                                />
                              );
                            })
                          ) : (
                            <>No data</>
                          )}
                        </>
                    </div>
                    

                    <div className="pages pagination-margin">
                    {totalCountries>0 ? (
                         <Pagination totalRecords={totalCountries} pageLimit={18} pageNeighbours={1} onPageChanged={this.list_p} />
    
                      ) : (
                        <>No data</>
                      )}
                  
                    
                    </div>
                    
                  </div>
                 
                </div>
              </div>
            </div>
          </section>
        </div>
      </Main>
    );
  };
  onPageChanged = data => {
    const { allCountries } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const offset = (currentPage - 1) * pageLimit;
    const currentCountries = allCountries.slice(offset, offset + pageLimit);

   // this.setState({ currentPage, currentCountries, totalPages });
  //   axios.get(`/api/countries?page=${currentPage}&limit=${pageLimit}`)
  //     .then(response => {
  //       const currentCountries = response.data.countries;
  //       this.setState({ currentPage, currentCountries, totalPages });
  //     });
  }
  
}

export default PageBerita;
