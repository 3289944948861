import React from "react";
//import { Col, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const ComGridRuangan = (props) => {
  //let nama="detailruangan?kode="+props.data.kode;
  return (
    <>
        <div className="col-md-12 col-lg-12 col-xl-4">
            <div className="grid-block main-block t-grid-block">
                <div className="main-img t-grid-img">
                    <Link to={'detailruangan?kode='+props.data.url}>
                        <img src={props.data.url_image}  className="img-fluid" alt={props.data.nama_ruang}/>
                    </Link>
                    <div className="main-mask">
                        <ul className="list-unstyled list-inline offer-price-1">
                            <li className="price"><span className="divider">|</span><span className="pkg">Lantai: {props.data.kd_lantai}</span></li>
                        </ul>
                    </div>
                </div>
                
                <div className="block-info t-grid-info">
                   
                    
                    <h3 className="block-title"><a href="tour-detail-left-sidebar.html">{props.data.nama_ruang}</a></h3>
 
                    <p> Ukuran: -{props.data.luas_ruang}; {props.data.spek_ruang}</p>
                   
                </div>
            </div>
        </div>
    </>
  );
};

export default ComGridRuangan;
